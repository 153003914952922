import React, { useState, useEffect } from 'react';
import { AtpAgent, RichText } from '@atproto/api';
import './App.css';
import Screenshot from './screenshot.png'; // Adjust the path accordingly

const LoadingIndicator = () => {
  return (
    <div className="loading-indicator">
      <div className="spinner"></div>
    </div>
  );
};

const AgeConfirmationModal = ({ isVisible, onConfirm }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Welcome to Community Sources</h2>
        <h4>An effort to bring the best out of social media and promote citizen journalism.</h4>
        
        <img className="image" src={Screenshot}></img>
       <p>We encourage respectful behavior, but due to the unpredictable nature of content on social media we ask that only people 16 years or older use the app. </p>
       <button className="confirm-button" onClick={onConfirm}>
        Continue (I am 16 or older)
       </button>
      </div>
    </div>
  );
};

const App = () => {
  const [feed, setFeed] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const preferredLanguages = navigator.language || 'en-US';
  const apiKey = process.env.REACT_APP_BSKY_PASSWORD;
  const [isAgeConfirmed, setIsAgeConfirmed] = useState(false);
  
  useEffect(() => {
    const ageConfirmed = localStorage.getItem('ageConfirmed');
    if (ageConfirmed === 'true') {
      setIsAgeConfirmed(true);
    }
  }, []);
  
  const handleConfirmAge = () => {
    localStorage.setItem('ageConfirmed', 'true');
    setIsAgeConfirmed(true);
  };


  const feedLinks = [
    'at://did:plc:hd4p7hmwqy3egilcv6lgjpzf/app.bsky.feed.generator/aaap7msb6vmdg',
    'at://did:plc:hd4p7hmwqy3egilcv6lgjpzf/app.bsky.feed.generator/aaap7i2f6e3kq',
    'at://did:plc:hd4p7hmwqy3egilcv6lgjpzf/app.bsky.feed.generator/aaacsjo76q5g4',
    'at://did:plc:hd4p7hmwqy3egilcv6lgjpzf/app.bsky.feed.generator/aaab4n3ofdehe',
    'at://did:plc:hd4p7hmwqy3egilcv6lgjpzf/app.bsky.feed.generator/aaaburmrbef3k',
    'at://did:plc:hd4p7hmwqy3egilcv6lgjpzf/app.bsky.feed.generator/aaab4xs76ypte',
    'at://did:plc:hd4p7hmwqy3egilcv6lgjpzf/app.bsky.feed.generator/aaabut5jyrc6c',
    'at://did:plc:hd4p7hmwqy3egilcv6lgjpzf/app.bsky.feed.generator/gggp7i2f6e3kq',
  ];

const formatTimeAgo = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const differenceInSeconds = Math.floor((now - date) / 1000);
  
    if (differenceInSeconds < 60) {
      return differenceInSeconds === 1
        ? `${differenceInSeconds} minute ago`
        : `${differenceInSeconds} minutes ago`;
    } else if (differenceInSeconds < 3600) {
      const minutes = Math.floor(differenceInSeconds / 60);
      return minutes === 1
        ? `${minutes} minute ago`
        : `${minutes} minutes ago`;
    } else if (differenceInSeconds < 86400) {
      const hours = Math.floor(differenceInSeconds / 3600);
      return hours === 1
        ? `${hours} hour ago`
        : `${hours} hours ago`;
    } else {
      const days = Math.floor(differenceInSeconds / 86400);
      return days === 1
        ? `${days} day ago`
        : `${days} days ago`;
    }
  };

  useEffect(() => {
    const fetchFeed = async () => {
      const agent = new AtpAgent({ service: 'https://bsky.social' });

      try {
        await agent.login({
          identifier: 'communitysources.bsky.social',
          password: `${apiKey}`,
        });

        const { data } = await agent.api.app.bsky.feed.getFeed(
          {
            feed: feedLinks[activeTab],
            limit: 100,
          },
          {
            headers: {
              'Accept-Language': preferredLanguages,
            },
          }
        );

        const { feed: postsArray } = data;

        const processedPosts = await Promise.all(
          postsArray.map(async (item) => {
            const rt = new RichText({ text: item.post.record.text });
            await rt.detectFacets(agent);

            let markdown = '';
            for (const segment of rt.segments()) {
              if (segment.isLink()) {
                markdown += `${segment.link?.uri}`;
              } else if (segment.isMention()) {
                markdown += `[${segment.text}](https://my-bsky-app.com/user/${segment.mention?.did})`;
              } else {
                markdown += segment.text;
              }
            }
console.log('Embed Data:', item.post.record);
            return {
              ...item,
              markdown,
            };
          })
        );

        setFeed(processedPosts);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchFeed();
  }, [activeTab, preferredLanguages]);

const renderEmbed = (embed) => {
    if (embed?.$type === 'app.bsky.embed.external') {
    const { uri, title, description } = embed.external;
    return (
      <div className="embed-card">
        <a href={uri} target="_blank" rel="noopener noreferrer">
          <div className="embed-content">
            <h4>{title}</h4>
            <h5>{uri}</h5>
            <p>{description}</p>
          </div>
        </a>
      </div>
      );
    }
  
    return null;
  };

const renderLink = (facets, embed) => {
  if (embed?.$type !== 'app.bsky.embed.external') {
    if (!facets || !Array.isArray(facets)) {
      return null; // Return null if facets is undefined, null, or not an array
    }
  
    let uriLink = '';
  
    // Iterate through each facet
    for (const facet of facets) {
      // Check if facet has features
      if (facet.features) {
        // Iterate through each feature
        for (const feature of facet.features) {
          // Check if the feature is a link
          if (feature.$type === 'app.bsky.richtext.facet#link') {
            uriLink = feature.uri;
            break;
          }
        }
      }
      if (uriLink) break; // Stop if uri is found
    }
  
    // Render the link if found
    return uriLink ? (
      <div className="embed-card">
        <a href={uriLink} target="_blank" rel="noopener noreferrer">
          <div className="embed-content">
            <br></br>
            <h4>{uriLink}</h4>
            <br></br>
          </div>
        </a>
      </div>
    ) : null;
  }
  return null;
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  if (error) {
    return <div>Error fetching feed: {error.message}</div>;
  }
  
 const handleModalOpen = () => setIsModalOpen(true);
 const handleModalClose = () => setIsModalOpen(false);

  return (
 
    <div className="App">
   
    <AgeConfirmationModal isVisible={!isAgeConfirmed} onConfirm={handleConfirmAge} />
    {isAgeConfirmed && (
      <div className={`App ${!loading ? 'fade-in' : ''}`}>
      <h1 className="appTitle">Community Sources</h1>
      <div className="tabs">
        {['📈Sources', '🏛️Gov', '🪙Econ', '🧬Sci', '🍿Film', '🎙️Pod', '🎸Music'].map((tab, index) => (
          <button key={index} onClick={() => setActiveTab(index)} className={activeTab === index ? 'active' : ''}>
            {tab}
          </button>
        ))}
      </div>
      <div className="feed">
        {feed.map((item) => (
          <div className="item" key={item.post.uri}>
            <p>{formatTimeAgo(item.post.record.createdAt)}</p>
            {renderEmbed(item.post.record.embed)}
            {renderLink(item.post.record.facets, item.post.record.embed)}
            <div dangerouslySetInnerHTML={{ __html: item.markdown }} />
            <div className="row">
            <a style={{marginRight: "auto", fontSize: "10pt",}} className="viewLink" href={`https://bsky.app/profile/${item.post.author.did}/post/${item.post.uri.split('/').pop()}`} target="_blank" rel="noopener noreferrer">by {item.post.author.displayName}</a>
            <div></div>
            <a style={{marginLeft: "auto"}} className="viewLink" href={`https://bsky.app/profile/${item.post.author.did}/post/${item.post.uri.split('/').pop()}`} target="_blank" rel="noopener noreferrer">
              from 🦋Bluesky
            </a>
            </div>
          </div>
        ))}
      </div>
   
 {/* Floating Action Button */}
          <div className="fab" onClick={handleModalOpen}>
            +
          </div>
    
          {/* Modal */}
         {isModalOpen && (
           <div className="modal">
             <div className="modal-content">
               <h2>Post to Community Sources</h2>
               <p>
                 by posting on Bluesky and including a cited link from one of the following sources. Your post will automatically appear in our custom feeds.
               </p>
               <div className="source-links">
                  <button onClick={() => window.open('https://bsky.app', '_blank')}>
                    🦋Bluesky
                  </button>
                  {/* Add more government links here */}
                </div>
               <div className="source-category">
                 <h3>Government</h3>
                 <div className="source-links">
                   <button onClick={() => window.open('https://www.usa.gov', '_blank')}>
                     An official .gov site
                   </button>
                   {/* Add more government links here */}
                 </div>
               </div>
               <div className="source-category">
                 <h3>Economy</h3>
                 <div className="source-links">
                 <button onClick={() => window.open('https://www.stlouisfed.org', '_blank')}>
                    FRED/St. Louis Fed
                  </button>
                   <button onClick={() => window.open('https://www.bea.gov', '_blank')}>
                     BEA
                   </button>
                   <button onClick={() => window.open('https://www.bls.gov', '_blank')}>
                     BLS
                   </button>
                   <button onClick={() => window.open('https://www.imf.org', '_blank')}>
                     IMF
                   </button>
                   <button onClick={() => window.open('https://www.oecd.org', '_blank')}>
                     OECD
                   </button>
                   <button onClick={() => window.open('https://ec.europa.eu/eurostat', '_blank')}>
                     Eurostat
                   </button>
                   <button onClick={() => window.open('https://unstats.un.org', '_blank')}>
                     UN Stats
                   </button>
                 </div>
               </div>
               <div className="source-category">
                 <h3>Science</h3>
                 <div className="source-links">
                   <button onClick={() => window.open('https://arxiv.org', '_blank')}>
                     arXiv.org
                   </button>
                   <button onClick={() => window.open('https://www.nih.gov', '_blank')}>
                     NIH.gov
                   </button>
                   <button onClick={() => window.open('https://www.nasa.gov', '_blank')}>
                     NASA.gov
                   </button>
                   <button onClick={() => window.open('https://www.science.org', '_blank')}>
                     Science.org
                   </button>
                   <button onClick={() => window.open('https://www.cell.com', '_blank')}>
                     Cell.com
                   </button>
                   <button onClick={() => window.open('https://www.pnas.org', '_blank')}>
                     PNAS.org
                   </button>
                 </div>
               </div>
               <div className="source-category">
                 <h3>Movies & TV</h3>
                 <div className="source-links">
                   <button onClick={() => window.open('https://www.imdb.com', '_blank')}>
                     IMDb
                   </button>
                   <button onClick={() => window.open('https://www.rottentomatoes.com', '_blank')}>
                     Rotten Tomatoes
                   </button>
                   <button onClick={() => window.open('https://www.netflix.com', '_blank')}>
                     Netflix
                   </button>
                   <button onClick={() => window.open('https://www.hulu.com', '_blank')}>
                     Hulu
                   </button>
                   <button onClick={() => window.open('https://www.amazon.com/gp/video/', '_blank')}>
                     Amazon Prime
                   </button>
                   <button onClick={() => window.open('https://play.max.com', '_blank')}>
                     Max
                   </button>
                 </div>
               </div>
               <div className="source-category">
                 <h3>Music</h3>
                 <div className="source-links">
                   <button onClick={() => window.open('https://open.spotify.com/artist', '_blank')}>
                     Spotify Music
                   </button>
                   <button onClick={() => window.open('https://music.apple.com', '_blank')}>
                     Apple Music
                   </button>
                   <button onClick={() => window.open('https://www.soundcloud.com', '_blank')}>
                     SoundCloud
                   </button>
                 </div>
               </div>
               <div className="source-category">
                 <h3>Podcasts</h3>
                 <div className="source-links">
                   <button onClick={() => window.open('https://podcasts.apple.com', '_blank')}>
                     Apple Podcasts
                   </button>
                   <button onClick={() => window.open('https://open.spotify.com/episode/', '_blank')}>
                     Spotify Podcasts
                   </button>
                 </div>
               </div>
               <button className="close" onClick={handleModalClose}>
                 Close
               </button>
             </div>
           </div>
         )}
          </div>
       )}
        </div>
      );
   
    };
    
    export default App;